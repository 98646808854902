import clsx from "clsx";
import React from "react";

const Hero = ({ children }) => {
	return (
		<section
			className={clsx(
				"relative w-screen px-14 flex flex-col items-center",
				"md:px-20",
				"lg:h-[calc(100vh-14rem)] lg:px-28"
			)}
		>
			<div className={clsx("w-full h-full overflow-hidden flex justify-center")}>
				<div className={"aspect-[3/2] overflow-hidden flex"}>
					<div className={"w-screen h-full flex justify-center items-center"}>
						{children}
					</div>
				</div>
			</div>
		</section>
	);
};

export default Hero;
