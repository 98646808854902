import { motion } from "framer-motion";
import { graphql } from "gatsby";
import Image from "gatsby-plugin-sanity-image";
import React, { forwardRef, useCallback, useEffect } from "react";
import Background from "../components/Background";
import Content from "../components/content/Content";
import Hero from "../components/Hero";
import { useNavStore } from "../state/useStore";
import Seo from "../components/Seo";
import imageUrlBuilder from "@sanity/image-url";
import { configuredSanityClient } from "../components/Client";
import Title from "../components/Title";
import ReactPlayer from "react-player/vimeo";

const builder = imageUrlBuilder(configuredSanityClient);

function urlFor(source) {
	return builder.image(source);
}

const Page = forwardRef(({ pageContext, location, data }, ref) => {
	const { nextFirst, previousLast } = pageContext;
	const { _id, title, hero, background, content, heroType, heroVideo } = data.page;

	const setPrevious = useNavStore((state) => state.setPrevious);
	const setNext = useNavStore((state) => state.setNext);
	const exit = useNavStore((state) => state.exit);

	const hasWindow = typeof window !== "undefined";

	useEffect(() => {
		setPrevious(previousLast);
		setNext(nextFirst);
	}, [setPrevious, setNext, previousLast, nextFirst]);

	const direction = useCallback(() => {
		if (!hasWindow || !window?.currentPath) {
			return 0;
		}
		switch (location?.state?.direction) {
			case "fromHome":
				return 0;

			case "left":
				return "-100%";

			case "right":
				return "100%";

			default:
				return 0;
		}
	}, [location?.state?.direction, hasWindow]);

	const opacity = useCallback(() => {
		switch (location?.state?.direction) {
			case "fromHome":
				return 0;

			case "left":
				return 1;

			case "right":
				return 1;

			default:
				return 1;
		}
	}, [location?.state?.direction]);

	return (
		<>
			<motion.div
				initial={{ opacity: opacity(), x: direction() }}
				animate={{ opacity: 1, x: 0 }}
				exit={{ opacity: 1, x: `${exit}%` }}
				transition={{ duration: 1 }}
				className={"flex flex-col z-100 "}
				key={_id}
				ref={ref}
			>
				<Hero>
					<motion.div
						initial={{ scale: 1.2 }}
						whileInView={{ scale: 1 }}
						viewport={{ once: true }}
						transition={{ duration: 0.8 }}
						className={"w-full h-full hero-wrapper"}
					>
						{!heroType && hero && (
							<Image
								{...hero}
								alt={hero.alt || ""}
								width={1500}
								style={{
									width: "100%",
									height: "100%",
									objectFit:
										hero.asset.metadata.dimensions.aspectRatio <= 1
											? "contain"
											: "cover",
								}}
								loading="eager"
							/>
						)}
						{heroType && heroVideo && (
							<ReactPlayer
								url={heroVideo}
								width="100%"
								height="100%"
								playing={true}
								playsinline={true}
								muted={true}
								controls={false}
								loop={true}
								config={{
									vimeo: {
										playerOptions: { responsive: true, color: "fbf7e9" },
									},
								}}
							/>
						)}
					</motion.div>
				</Hero>
				<Title title={title} />
				<Content contents={content} />
			</motion.div>
			{background && (
				<motion.div
					initial={{
						opacity: 0,
					}}
					animate={{ opacity: 1 }}
					exit={{ opacity: 0 }}
					transition={{
						duration: 1,
					}}
					className={"fixed w-screen h-screen top-0 -z-50"}
				>
					<Background background={background} />
				</motion.div>
			)}
		</>
	);
});

export default Page;

export const Head = ({ location, data }) => {
	const { page } = data;

	const bannerSeo = page.seoImage?.asset?._id
		? urlFor(page.seoImage.asset._id).width(1200).url()
		: null;
	
	const bannerHero = page?.hero?.asset?._id ? urlFor(page.hero.asset._id).width(1200).url() : null
	
	return (
		<Seo
			title={page.seoTitle || page.title}
			description={page.seoDescription}
			banner={
				bannerSeo || bannerHero || urlFor(data.global.seoImage.asset._id).width(1200).url()
			}
			pathname={location.pathname}
		/>
	);
};

export const query = graphql`
	query getSinglePage($slug: String) {
		page: sanityPage(prefixedSlug: { current: { eq: $slug } }) {
			_id
			title
			heroType
			hero {
				alt
				...ImageWithPreview
				asset {
					_id
					metadata {
						dimensions {
							aspectRatio
						}
					}
				}
			}
			heroVideo
			background {
				... on SanityColour {
					_type
					colour
				}
				... on SanityTexture {
					_type
					image {
						asset {
							url
							metadata {
								dimensions {
									height
									width
								}
								palette {
									dominant {
										background
									}
								}
							}
						}
					}
				}
			}
			content {
				... on SanityModuleParagraph {
					_key
					_type
					_rawText
					centre
				}
				... on SanityModuleQuote {
					_key
					_type
					_rawQuote
					quoteDirection
				}
				... on SanityModuleVideo {
					_key
					_type
					video
					controls
				}
			}
			seoTitle
			seoDescription
			seoImage {
				asset {
					_id
				}
			}
		}
		global: sanityGlobal {
			seoImage {
				asset {
					_id
				}
			}
		}
	}
`;
